<template>
  <v-menu :close-on-content-click="false" open-on-hover>
    <template #activator="{ props }">
      <v-btn plain v-bind="props" class="d-flex align-center">
        <v-icon>{{ mdiAccount }}</v-icon>
        <span class="ml-2">{{ username }}</span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item dense @click="logout">
        <div>
          <v-icon class="mr-2">{{ mdiLogout }}</v-icon>
          <span>Logout</span>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { mdiAccount, mdiLogout } from '@mdi/js'

const { unload, username } = useSecurityStore()

function logout() {
  unload()
  navigateTo('/login')
}
</script>
