<template>
  <v-app v-if="permissions.DEVICE_CONTROL_PANEL.READ()">
    <sidebar-device-control v-model="visible" v-model:selected-command="selectedCommand" />
    <headbar-device-control-panel v-model="visible" />
    <v-main v-if="selectedCommand">
      <NuxtPage :selected-command="selectedCommand" />
      <reusable-notification v-if="notificationConfig" v-model="showNotification" :config="notificationConfig" />
      <reusable-prompt v-for="prompt in promptQueue" :key="prompt.id" v-bind="prompt" />
    </v-main>
  </v-app>
</template>

<script lang="ts" setup>
import { useDisplay } from 'vuetify'
import type { CommandInformation } from '~/types/DeviceControlPanel'
import type { NotificationConfig, PromptConfig } from '~/types/Reusable'

const { permissions } = storeToRefs(useSecurityStore())
const visible = ref(true)
const { mobile } = useDisplay()
const selectedCommand = ref<CommandInformation | null>(null)
const { $eventBus } = useNuxtApp()
const notificationConfig = ref<NotificationConfig>()
const showNotification = ref(false)
const promptQueue = ref<PromptConfig<Record<string, never>>[]>([])

onMounted(() => {
  visible.value = !mobile.value
})

$eventBus.on('show-prompt', (event) => {
  promptQueue.value.push(event)
})
$eventBus.on('hide-prompt', () => {
  promptQueue.value.shift()
})
$eventBus.on('show-notification', (event) => {
  showNotification.value = false
  nextTick(() => {
    notificationConfig.value = event
    showNotification.value = true
  })
})
$eventBus.on('hide-notification', () => {
  showNotification.value = false
})
</script>
