<template>
  <v-app-bar density="compact">
    <v-app-bar-nav-icon density="comfortable" @click="model = !model" />
    <v-toolbar-title v-if="device" class="pl-0">
      <v-list-item>
        <template #prepend>
          <v-icon :icon="status[device.status!].icon" :color="status[device.status!].color" size="small" />
        </template>
        <v-list-item-title>{{ device.hexacode }}</v-list-item-title>
        <v-list-item-subtitle>{{ device.name }}</v-list-item-subtitle>
      </v-list-item>
    </v-toolbar-title>
    <v-spacer />
    <headbar-device-control-panel-menu />
  </v-app-bar>
</template>

<script lang="ts" setup>
import type { Subscription } from 'rxjs'
import type { Device } from '~/types/Device'
import { findDeviceByHexacode } from '~/utils/backend/devices-api'
import { subscribeToDeviceStatusEvents } from '~/utils/backend/events-api'
import type { DeviceStatusEvent } from '~/types/WebSocketEventTypes'

const model = defineModel<boolean>({ required: true })
const { query } = useRoute()
const hexacode = query['hexacode'] as string
const status = Status
const device = ref<Device | null>(null)
const { $notifications } = useNuxtApp()
const sessionSubscription = ref<Subscription | null>(null)
const eventsSubscription = ref<Subscription | null>(null)
const { getDeviceStatusEventStream, getSessionSubject } = useWebSocketComposable()

onMounted(() => {
  findDeviceByHexacode(hexacode).then((r) => {
    device.value = r.data
    sessionSubscription.value = getSessionSubject().subscribe((sessionId: string) => {
      subscribeToDeviceStatusEvents(sessionId, hexacode)
        .then(() => {
          console.log('Successfully subscribed to device status events')
        })
        .catch((error) => {
          console.error('Error subscribing to device status events:', error)
        })
    })

    eventsSubscription.value = getDeviceStatusEventStream(hexacode).subscribe((event: DeviceStatusEvent) => {
      device.value!.status = event.payload.status
    })
  }).catch(() => $notifications.error('Could not load device details'))
})

onUnmounted(() => {
  sessionSubscription.value?.unsubscribe()
  eventsSubscription.value?.unsubscribe()
})
</script>
