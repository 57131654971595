<template>
  <v-navigation-drawer v-model="model" width="240">
    <template #prepend>
      <v-list-item class="d-flex justify-center px-0 mb-1 logo" :height="48" :elevation="4">
        <img :src="logo">
      </v-list-item>
      <v-list-item class="flex justify-center">
        <v-text-field v-model="searchHint" label="Search" hide-details dense outlined :append-inner-icon="mdiMagnify" />
      </v-list-item>
    </template>
    <v-list nav dense>
      <div v-for="(command, index) in filteredCommands" :key="`command-${index}`">
        <v-list-item :active="selectedCommand!== null && selectedCommand.opCode === command.opCode" @click="handleCommandSelection(command)">
          <v-list-item-title>{{ command.opCode }}</v-list-item-title>
          <v-list-item-subtitle>{{ command.label }}</v-list-item-subtitle>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import {
  mdiMagnify
} from '@mdi/js'
import logo from '@/assets/logo-vertical.png'
import type { CommandInformation } from '~/types/DeviceControlPanel'

const { permissions } = storeToRefs(useSecurityStore())
const model = defineModel<boolean>({ required: true })
const searchHint = ref<string>('')
const selectedCommand = defineModel<CommandInformation | null>('selectedCommand', { required: true })
const commands = ref<CommandInformation[]>([
  { opCode: 'IR', label: 'Id Read', fallback: true, cache: true, active: false, enabled: true, component: 'IdRead', type: 'DeviceIdReadRequest' },
  { opCode: 'DIR', label: 'Information Request', fallback: false, cache: false, active: false, enabled: true, component: 'InformationRequest', type: 'DeviceInformationRequest' },
  { opCode: 'LW', label: 'License Write', fallback: false, cache: false, active: false, enabled: permissions.value.DEVICE_CONTROL_PANEL.WRITE(), component: 'LicenseWrite', type: 'DeviceLicenseWriteRequest' },
  { opCode: 'LR', label: 'License Read', fallback: false, cache: false, active: false, enabled: true, component: 'LicenseRead', type: 'DeviceLicenseReadRequest' },
  { opCode: 'PR', label: 'Password Read', fallback: false, cache: false, active: false, enabled: true, component: 'PasswordRead', type: 'DevicePasswordReadRequest' },
  { opCode: 'PW', label: 'Password Write', fallback: false, cache: false, active: false, enabled: permissions.value.DEVICE_CONTROL_PANEL.WRITE(), component: 'PasswordWrite', type: 'DevicePasswordWriteRequest' },
  { opCode: 'TR', label: 'Time Read', fallback: true, cache: true, active: false, enabled: true, component: 'TimeRead', type: 'DeviceTimeReadRequest' },
  { opCode: 'VR', label: 'Version Read', fallback: true, cache: false, active: false, enabled: true, component: 'VersionRead', type: 'DeviceVersionReadRequest' },
  { opCode: 'CR', label: 'Configuration Read', fallback: true, cache: true, active: false, enabled: true, component: 'ConfigurationRead', type: 'DeviceConfigurationReadRequest' },
  { opCode: 'CE', label: 'Configuration Read Extended', fallback: true, cache: true, active: false, enabled: true, component: 'ConfigurationReadExtended', type: 'DeviceConfigurationReadExtendedRequest' },
  { opCode: 'AR', label: 'Alarm Read', fallback: true, cache: true, active: false, enabled: true, component: 'AlarmRead', type: 'DeviceAlarmReadRequest' },
  { opCode: 'AE', label: 'Alarm Read Extended', fallback: true, cache: true, active: false, enabled: true, component: 'AlarmReadExtended', type: 'DeviceAlarmReadExtendedRequest' },
  { opCode: 'AL', label: 'Alarm List', fallback: true, cache: true, active: false, enabled: true, component: 'AlarmList', type: 'DeviceAlarmListRequest' },
  { opCode: 'GR', label: 'Page Status', fallback: false, cache: true, active: false, enabled: true, component: 'PageStatus', type: 'DevicePageStatusRequest' },
  { opCode: 'GE', label: 'Page Status Extended', fallback: true, cache: true, active: false, enabled: true, component: 'PageStatusExtended', type: 'DevicePageStatusExtendedRequest' },
  { opCode: 'RE', label: 'Read Extended', fallback: false, cache: true, active: false, enabled: true, component: 'ReadExtended', type: 'DeviceReadExtendedRequest' },
  { opCode: 'RP', label: 'Read Page', fallback: true, cache: true, active: false, enabled: true, component: 'ReadPage', type: 'DeviceReadPageRequest' },
  { opCode: 'RS', label: 'Read Short', fallback: false, cache: true, active: false, enabled: true, component: 'ReadShort', type: 'DeviceReadShortRequest' },
  { opCode: 'WE', label: 'Write Extended', fallback: false, cache: false, active: false, enabled: permissions.value.DEVICE_CONTROL_PANEL.WRITE(), component: 'WriteExtended', type: 'DeviceWriteExtendedRequest' },
  { opCode: 'WS', label: 'Write Short', fallback: false, cache: false, active: false, enabled: permissions.value.DEVICE_CONTROL_PANEL.WRITE(), component: 'WriteShort', type: 'DeviceWriteShortRequest' },
  { opCode: 'BR', label: 'Buffer Header Read', fallback: true, cache: true, active: false, enabled: true, component: 'BufferHeaderRead', type: 'DeviceBufferHeaderReadRequest' },
  { opCode: 'BW', label: 'Buffer Header Write', fallback: true, cache: false, active: false, enabled: permissions.value.DEVICE_CONTROL_PANEL.WRITE(), component: 'BufferHeaderWrite', type: 'DeviceBufferHeaderWriteRequest' },
  { opCode: 'BC', label: 'Buffer Header Clear', fallback: true, cache: false, active: false, enabled: permissions.value.DEVICE_CONTROL_PANEL.WRITE(), component: 'BufferHeaderClear', type: 'DeviceBufferHeaderClearRequest' },
  { opCode: 'BD', label: 'Buffer Data Read', fallback: true, cache: true, active: false, enabled: true, component: 'BufferDataRead', type: 'DeviceBufferDataReadRequest' },
  { opCode: 'BI', label: 'Buffer Info Read', fallback: true, cache: false, active: false, enabled: true, component: 'BufferInfoRead', type: 'DeviceBufferInfoReadRequest' },
  { opCode: 'XBH', label: 'Buffer Header Read Extended', fallback: true, cache: true, active: false, enabled: true, component: 'BufferHeaderReadExtended', type: 'DeviceBufferHeaderReadExtendedRequest' },
  { opCode: 'XBE', label: 'Buffer Header Data Read Extended', fallback: true, cache: true, active: false, enabled: true, component: 'BufferHeaderDataReadExtended', type: 'DeviceBufferDataReadExtendedRequest' },
  { opCode: 'ER', label: 'Extended Read', fallback: false, cache: false, active: false, enabled: true, component: 'ExtendedRead', type: 'DeviceExtendedReadRequest' },
  { opCode: 'EW', label: 'Extended Write', fallback: false, cache: false, active: false, enabled: permissions.value.DEVICE_CONTROL_PANEL.WRITE(), component: 'ExtendedWrite', type: 'DeviceExtendedWriteRequest' },
  { opCode: 'SR', label: 'Slave Header Read', fallback: true, cache: false, active: false, enabled: true, component: 'SlaveHeaderRead', type: 'DeviceSlaveHeaderReadRequest' },
  { opCode: 'SW', label: 'Slave Header Write', fallback: true, cache: false, active: false, enabled: permissions.value.DEVICE_CONTROL_PANEL.WRITE(), component: 'SlaveHeaderWrite', type: 'DeviceSlaveHeaderWriteRequest' },
  { opCode: 'SC', label: 'Slave Header Clear', fallback: true, cache: false, active: false, enabled: permissions.value.DEVICE_CONTROL_PANEL.WRITE(), component: 'SlaveHeaderClear', type: 'DeviceSlaveHeaderClearRequest' },
  { opCode: 'XWR', label: 'Extended Data Write', fallback: false, cache: false, active: false, enabled: permissions.value.DEVICE_CONTROL_PANEL.WRITE(), component: 'ExtendedDataWrite', type: 'DeviceExtendedDataWriteRequest' },
  { opCode: 'XRD', label: 'Extended Data Read', fallback: false, cache: false, active: false, enabled: true, component: 'ExtendedDataRead', type: 'DeviceExtendedDataReadRequest' },
  { opCode: 'XFS', label: 'File system', fallback: false, cache: false, active: false, enabled: true, component: 'FileSystemRequest', type: 'DeviceFileSystemRequest' },
  { opCode: 'XTI', label: 'TimeZone Information', fallback: false, cache: false, active: false, enabled: true, component: 'TimeZoneInfoRequest', type: 'DeviceTimeZoneInfoRequest' },
  { opCode: 'XTS', label: 'Date/Time Sync', fallback: false, cache: false, active: false, enabled: true, component: 'DateTimeSyncRequest', type: 'DeviceDateTimeSyncRequest' },
])

function handleCommandSelection(command: CommandInformation) {
  selectedCommand.value = { ...command }

  commands.value.forEach((cmd) => {
    cmd.active = cmd.opCode === command.opCode
  })
}

const filteredCommands = computed(() => {
  if (searchHint.value !== '') {
    const lowerCaseHint = searchHint.value.toLowerCase()
    return commands.value.filter(c => c.enabled).filter(c =>
      c.opCode.toLowerCase().indexOf(lowerCaseHint) >= 0
      || c.label.toLowerCase().indexOf(lowerCaseHint) >= 0
    )
  }
  return commands.value.filter(c => c.enabled)
})

onMounted(() => {
  selectedCommand.value = commands.value[0]
  handleCommandSelection(commands.value[0])
})
</script>

<style lang="css" scoped>
.logo img {
  width: 100%;
  height: 46px;
}
</style>
